<template>
    <!--站点统计-->
    <div>
        <div style="background-color:#FFFFFF;margin-left:16px;margin-right:12px;padding-bottom:2vh;opacity:0.8;">
          <div style="max-width:300px;font-size:18px;">
            <div style="font-weight:600;text-align:center;padding:10px;color:#0fb9b1;font-size:16px;">
              <div>vue.js + node.js + mongodb</div>
              <div>搭建的个人独立网站</div>
            </div>
            <div style="margin-top:2px;padding-left:10px;font-family:'Microsoft YaHei';font-weight:500;">
              <div style="font-weight:700;font-size:16px;">站点统计</div>
              <div style="display:flex;flex-wrap:wrap;">
                <div style="font-size:15px;margin-top:10px;padding-left:10px;width:40%;">文章数 : {{statistics.article_num}}</div>
                <div style="font-size:15px;margin-top:10px;padding-left:10px;width:40%;">总获赞 : {{statistics.like_num}}</div>
                <div style="font-size:15px;margin-top:6px;padding-left:10px;width:40%;">总访客数: {{statistics.user_num}}</div>
                <!-- <div style="font-size:15px;margin-top:6px;padding-left:10px;width:40%;">主页访问数: {{statistics.visitor_num}}</div> -->
                <div style="font-size:15px;margin-top:6px;padding-left:10px;width:40%;">运行天数 : {{statistics.days}}</div>
                <div style="font-size:15px;margin-top:6px;padding-left:10px;width:40%;">预留位</div>
              </div>
            </div>
          </div>
        </div>

        <div style="background-color:#ffffff;margin-left:16px;margin-right:12px;margin-top:0.5vh;padding-bottom:10px;">
          <div style="height:8px;"></div>
          <div style="font-weight:700;font-size:16px;margin:3px 0 8px 10px;">博客标签:</div>
          <div style="width:288px;display:flex;flex-wrap:wrap;align-content:flex-start;padding:0 6px;">
            <div class="label"  v-for="(item,index) in label_list" :key="index" :style="'background-color:'+item.color">{{item.name}}</div>
          </div>
          
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
          label_list:[
             {name:'JavaScript',color:'#9466ff'},
             {name:'Vue',color:'#f5cd79'},
             {name:'uni-app',color:'#0fb9b1'},
             {name:'Node.js',color:'#e77f67'},
             {name:'WEB',color:'#0fb9b1'},
             {name:'微信小程序',color:'#9466ff'},
             {name:'CSS3',color:'#e77f67'},
             {name:'JQuery',color:'#f5cd79'},
             {name:'Linux',color:'#e77f67'},
             {name:'数据库',color:'#9466ff'},
             {name:'后端相关',color:'#f5cd79'},
             {name:'踩坑记录',color:'#9466ff'},
          ],//标签列表
          statistics:{}
        };
    },
    mounted(){
      this.getStatistics();
    },
    methods: {
      getStatistics(){
        this.$axios.get(this.$url.getStatistics).then(res=>{
          this.statistics = res.data;
        })
      }
    },
};
</script>
<style scoped>
/*标签云*/
.label{
  padding:2px 10px;
  border-radius: 5px;
  margin:2px;
  height:16px;
  line-height: 16px;
  color: #ffffff;
  font-size:13px;
}
</style>