<template>
	<!--推荐位卡片组件-->
	<div>
		<!--推荐位-->
    	<div class="container" >
    	  <div class="card" >
			  <div style="position:absolute;margin-top:6px;height:300px;width:302px;color:rgb(182, 236, 243);">
            <div style="font-size:22px;font-weight:600;margin-top:0.2vh;margin-left:20px;">当前版本2.0</div>
            <div style="font-size:17px;margin-top:1.5vh;margin-left:10px;">All good luck is on the way →_→</div>
        </div>
        <div class="card-img-top" alt="Sora" style="display:flex;justify-content:center;">
          <div class="card_hover" style="position:absolute;height:44vh;width:302px;top:0;left:0;z-index:1000;"></div>
          <div>
            <div style="display:flex;justify-content:center;margin-top:8vh;">
                <img src="@/assets/img/touxiang.jpg" style="height:8vh;border-radius:50%;margin-top:-5vh;">
            </div>
            <div style="text-align:center;margin-top:1vh;font-size:18px;font-weight:700;">博主 : BaiYu</div>
            <div style="display:flex;justify-content:center;align-items:center;margin-top:1vh;">
              <img src="@/assets/icon/youxiang.png" style="height:16px;width:16px;margin-right:5px;">
              <div style="font-size:16px;">1477652015@qq.com</div>
            </div>
          </div>
        </div>
    	  <div class="card-body">
    	      <h5 class="card-title">@baiyu  
              <div style="display:inline;font-size:15px;color:#777777;margin-left:20px;">编程爱好者~</div>
            </h5>
    	      <p class="card-text">欢迎来到我的博客，在这里存放一些我的学习笔记与技术总结、分享一些小案例和踩坑记录。。</p>
            <!-- <a href="#" class="btn"  @click="goMessageBoard()">111111111111</a> -->
            <div></div>
    	  </div>
    	</div>
    </div>
	</div>
</template>
<script>
export default {
	name:'MyInfo',
	props: {
		//接收数据
	},
	data() {
		return {};
	},
	methods: {
    //去留言方法
    goMessageBoard(){
      this.$router.push({
          path: './messageBoard'
      })
    }
	},
};
</script>
<style   scoped>
.container {
  width: 330px;
  display: flex;
  justify-content: space-between;
}
.card {
  margin: 1em;
}
.card-img-top {
  position: relative;
  z-index: 2;
  width: 302px;
  height: 22vh;
  transform: translateY(52%);
  transition: 0.5s;
  background-image:url('~@/assets/img/mingpianbg.jpg');
  background-size:160% 160%;
}
.card-body {
  z-index: 1;
  box-sizing: border-box;
  padding: 1.25em;
  height: 21vh;
  background: #FFFFFF;
  transform: translateY(-50%);
  transition: 0.5s;
}
.card-title {
  margin: 0 0 0.5em 0;
  font-size: 1.1em;
  font-weight: bold;
  line-height: 1.2;
}
.card-text {
  margin: 2vh 0;
}
.btn {
  padding: 0.375em 0.75em;
  color:rgb(31, 137, 156);
  text-decoration: none;
  line-height: 1.5;
  border: 1px solid rgb(31, 137, 156);
  border-radius: 0.25em;
  transition: 0.5s;
}
.btn:hover {
  color: white;
  background: rgb(31, 137, 156);
}
.card-img-top:hover.card-img-top {
  transform: translateY(2%);
}
.card-img-top:hover~.card-body{
  transform: translateY(0%);
}

</style>