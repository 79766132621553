<template>
<div>
  <!--电脑-->
  <div class="fullPage" ref="fullPage">
    <div class="fullPageContainer" ref="fullPageContainer" @mousewheel="mouseWheelHandle" @DOMMouseScroll="mouseWheelHandle">
        <!--PC背景墙-->
        <div class="pc_bg" style="position:relactive;max-height:100vh;width:100vw;">
          <!--背景幻灯片-->
          <iframe src="/static/wowslider.html" frameborder="0" style="height:100vh;width:100vw"></iframe>
          <!--覆盖背景图 实现鼠标全局滚动事件-->
          <div style="position:absolute;top:0;height:100vh;width:100vw;z-index:0;">
            <!--欢迎弹窗-->
            <div style="display:flex;justify-content:center;padding-top:30vh;height:60vh;">
              <div style="font-size:30px;color:#FFFFFF;">
                <div style="font-weight:800;font-size:36px;" class="wow pulse" data-wow-duration ="2s" data-wow-delay ="0s">Welcome ,</div>
                <div style="margin-left:50px;font-weight:800;margin-top:10px;" class="wow bounceInRight" data-wow-duration ="2s" data-wow-delay ="0s">这里是BaiYu的技术博客</div>
                <div style="font-size:20px;margin-top:50px;margin-left:40px;" class="wow bounceInRight" data-wow-duration ="2s" data-wow-delay ="0s">⚆_⚆ 您是本站的第{{visitor_num}}个小伙伴</div>
              </div>
            </div>
            <!--向下滚动弹窗-->
            <div style="display:flex;align-items:center;justify-content:center;" >
              <div style="display:flex;align-items:center;justify-content:center;cursor:pointer;" @click="next()" class="wow bounceInUp" data-wow-duration ="5s" data-wow-delay ="0s">
                <div style="font-size:24px;font-weight:500;color:#FFFFFF;margin-right:10px;" >滚动鼠标</div>
                <div>
                  <img src="@/assets/icon/xiangxia.png" style="height:30px;width:40px;margin-top:2px;">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--第二页主体-->
        <div>
          <!--tab栏高度-->
          <div style="height:80px;"></div>
          <!--主体-->
          <div style="display:flex;justify-content:center;" >
            <div>
              <!-- 轮播图 -->
              <BYswiper :show_type="0" :swiper_list="swiper_list"></BYswiper>
              <!--文章详情-->
              <Recommend :recommend_list="recommend_list"></Recommend>
              <!--公安备案-->
              <div style="display:flex;align-items:center;justify-content:right;margin-top:5px;">
                <a href="http://beian.miit.gov.cn/" style="color:#F8F8F8;font-size:13px;margin-right:10px;">辽ICP备2022004807号</a>
                <img src="@/assets/icon/gongan.png" >
                <a href="http://beian.miit.gov.cn/" style="color:#F8F8F8;font-size:13px;margin-left:4px;">公安备21010402000433号</a>
              </div>
            </div>
            <div>
              <!--站点统计-->
              <Statistics></Statistics>
              <!--个人面板-->
              <MyInfo></MyInfo>
            </div>
          </div>
          
 
        </div>

    </div>
  </div>
  <!--手机-->
  <div class="h5_page" style="min-height:100vh;background-color:F9F9F9;">
      <!--tab栏高度-->
      <div style="height:7vh;"></div>
      <!-- 轮播图 -->
      <BYswiper :show_type="1" :swiper_list="swiper_list"></BYswiper>
      <div v-for="(item, index) in recommend_list" :key="index" style="display:flex;justify-content:center;">
          <div style="width:90vw;background-color:#FFFFFF;margin-top:2.3vh;">
            <div style="display:flex;">
              <!-- <img :src="item.img" style="height:42vw;width:42vw;"> -->
              <div>
                
              </div>
            </div>
          </div>
      </div>
  </div>
</div>
</template>
<script>
import BYswiper from '@/components/BYswiper/BYswiper'//轮播图组件
import BYcard from '@/components/BYcard/BYcard'//资料卡组件
import Recommend from './components/Recommend'//首页推荐位
import Statistics from './components/Statistics'//站点统计
import MyInfo from './components/MyInfo.vue'//我的信息
export default {
  components: {
    BYswiper,
    BYcard,
    Recommend,
    Statistics,
    MyInfo
	},
  data() {
    return {
      start_page:false,//只执行一次
      user_cip:'', //用户ip地址
      fullpage: {
        current: 1, // 当前的页面编号
        isScrolling: false, // 是否在滚动,是为了防止滚动多页，需要通过一个变量来控制是否滚动
        deltaY: 0, // 返回鼠标滚轮的垂直滚动量，保存的鼠标滚动事件的deleteY,用来判断是往下还是往上滚
      },
      swiper_list:[],//轮播图
      recommend_list:[],//推荐位
      visitor_num:'',//访客数
    };
  },

  mounted() {
    this.getUserCip();//获取ip
    this.ifComputer();//检测是否电脑登录
    this.$nextTick(() => {      
      new this.$wow().init()   
     });
    this.getHomeInfo();//获取首页内容
  },
  methods: {
    //获取首页内容
    getHomeInfo(){
       this.$axios.get(this.$url.getHomeInfo).then(res=>{
         this.swiper_list = res.data.swiper;
         this.recommend_list = res.data.recommend;
      })
    },
    //检测是否电脑登录
    ifComputer(){
      //若是电脑登录初始化不显示tab栏
      if(document.body.clientWidth>768){
          this.$store.commit('showTabs',false);//将变化存在vuex中
      }
    },
    //获取ip
    getUserCip() {
      //获取用户ip
      try{
          const cip = returnCitySN;
          this.user_cip = cip;
      }catch{
        
      }
       this.homeLogin();
    },
    //无感登录
    homeLogin(){
      let user_token = '';
      if(window.localStorage.getItem("user_info")){
        user_token = JSON.parse(window.localStorage.getItem("user_info")).token
      }
      this.$axios.post(this.$url.homeLogin,{
        token:user_token,
        ip:this.user_cip.cip,
        address:this.user_cip.cname,
      }).then(res=>{
        this.visitor_num = res.data.visitor_num;
        console.log(res,"记录访问数")
      })
    },
    //页面滚动方法
    next() {
      // 往下切换
      let len = 2; // 页面的个数
      if (this.fullpage.current + 1 <= len) {
        // 如果当前页面编号+1 小于总个数，则可以执行向下滑动
        this.fullpage.current += 1; // 页面+1
        this.move(this.fullpage.current); // 执行切换
        this.$store.commit('showTabs',true);
      }
    },
    pre() {
      // 往上切换
      if (this.fullpage.current - 1 > 0) {
        // 如果当前页面编号-1 大于0，则可以执行向下滑动
        this.fullpage.current -= 1; // 页面+1
        this.move(this.fullpage.current); // 执行切换
        this.$store.commit('showTabs',false);
      }
    },
    move(index) {
      this.fullpage.isScrolling = true; // 为了防止滚动多页，需要通过一个变量来控制是否滚动
      this.directToMove(index); //执行滚动
      setTimeout(() => {
        //这里的动画是1s执行完，使用setTimeout延迟1s后解锁
        this.fullpage.isScrolling = false;
      }, 1010);
    },
    directToMove(index) {
      let height = this.$refs["fullPage"].clientHeight; //获取屏幕的宽度
      let scrollPage = this.$refs["fullPageContainer"]; // 获取执行tarnsform的元素
      let scrollHeight; // 计算滚动的告诉，是往上滚还往下滚
      scrollHeight = -(index - 1) * height + "px";
      scrollPage.style.transform = `translateY(${scrollHeight})`;
      this.fullpage.current = index;
    },
    mouseWheelHandle(event) {
      // 监听鼠标监听
      // 添加冒泡阻止
      let evt = event || window.event;
      if (evt.stopPropagation) {
        evt.stopPropagation();
      } else {
        evt.returnValue = false;
      }
      if (this.fullpage.isScrolling) {
        // 判断是否可以滚动
        return false;
      }
      let e = event.originalEvent || event;
      this.fullpage.deltaY = e.deltaY || e.detail; // Firefox使用detail
      if (this.fullpage.deltaY > 0) {
        this.next();
      } else if (this.fullpage.deltaY < 0) {
        this.pre();
      }
    },
  },
};
</script>
<style scoped>
/* 媒体查询电脑 */
@media screen and (min-width: 768px) {
  .fullPage{
    height: 100vh;
    overflow: hidden;
  }
  .fullPageContainer{
    width: 100%;
    height: 100vh;
    transition: all linear 0.5s;
  }
  .h5_page{
    display: none;
  }
  .pc_welcome{
    height:300px;
    width: 500px;
    background-color: aqua;
  }
}
/* 媒体查询手机  在480到760之间*/
@media screen and (max-width: 768px) {
  .fullPage{
    display: none;
  }
}
</style>