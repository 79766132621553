<template>
  <!--首页推荐位-->
    <div>
        <div v-for="(item,index) in recommend_list" :key="index" class="card_body"  @click="goArticleById(item._id)" >
          <div class="box"> 
            <img :src="item.img" style="height:26vh;width:26vh;">
          </div>
          <div class="card_info">
              <div class="card_title">{{item.title}}</div>
              <div style="display:flex;align-items:center;height:2vh;">
                <div style="background-color:rgb(20, 189, 219);border-radius:10px;padding:2px 10px;color:#ffffff;font-size:14px;height:2vh;line-height:2vh;">{{item.type}}</div>
                <div style="font-size:14px;color:#777777;margin-left:20px;">创建时间 : {{item.create_time | timeFilter}}</div>
              </div>
              
              <div style="font-size:16px;height:11.5vh;margin-top:1vh;color:#000000;" class="font_line">
                {{item.description}}
              </div>
              <div style="display:flex;align-items:center;justify-content:flex-end;">
                <img src="@/assets/icon/yuedu.png" style="height:18px;width:18px;">
                <div style="margin-left:10px;margin-right:10px;font-size:14px;">阅读 : {{item.look}}</div>
                <img src="@/assets/icon/pinglun.png" style="height:18px;width:18px;">
                <div style="margin-left:10px;margin-right:10px;font-size:14px;">评论数 : {{item.comment_num}}</div>
                <img src="@/assets/icon/zan.png" style="height:18px;width:18px;">
                <div style="margin-left:10px;font-size:14px;">{{item.like}}</div>
              </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
      recommend_list:{
        type:Array,
        default:[],
      }
    },
    data() {
        return {
          
        };
    },
    methods: {
      goArticleById(id){
        this.$router.push({
          path: './article',
          query: {id: id}
        })
      }
    },
};
</script>
<style scoped>
/*鼠标滑过放大box内图特效*/
.box{
  overflow:hidden;
}
.box~.box{
  margin-left: 15px;
}
.box img{
  width: 100%;
  height: 100%;
  transition: all 0.5s;
}
.box:hover img{
  transform: scale(1.3);
}

/*弹性盒子超出行数自动变...*/
.font_line{
  display: -webkit-box;
	/*将对象转为弹性盒模型展示*/
	-webkit-box-orient: vertical;
	/*设置弹性盒模型子元素的排列方式*/
	-webkit-line-clamp: 4;
	/*限制文本行数*/
	overflow: hidden;
	/*超出隐藏*/
}

.card_body{
  width:40vw;
  height:26vh;
  display:flex;
  cursor:pointer;
  margin-top:1vh;
}
.card_info{
  height:26vh;
  width:calc(40vw - 26vh);
  background-color:#ffffff;
  padding: 0 1vw;
  opacity:0.8;
}
.card_title{
  line-height: 7vh;
  height:7vh;
  font-size:1.3vw;
  font-weight:600;
  color:#000000;
  display: -webkit-box;
	/*将对象转为弹性盒模型展示*/
	-webkit-box-orient: vertical;
	/*设置弹性盒模型子元素的排列方式*/
	-webkit-line-clamp: 1;
	/*限制文本行数*/
	overflow: hidden;
	/*超出隐藏*/
}
</style>