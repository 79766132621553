<template>
  <div class="recommendPage">
    <swiper :options="swiperOption" ref="mySwiper">
      <swiper-slide v-for="(item,index) in swiper_list" :key="index">
        <!--电脑-->
        <div v-if="show_type==0" style="height:30vh;width:40vw;cursor:pointer;" @click="goArticle(item._id)">
            <img :src="item.swiper_img" style="height:30vh;width:40vw;z-index:190;">
            <div style="position:absolute;height:30vh;width:40vw;left:0vw;bottom:0;font-size:30px;color:#ffffff;font-weight:700;display:flex;align-items:flex-end;">
              <div style="line-height:90px;padding-left:50px;">{{item.title}}</div>
            </div>
        </div>
        <!--手机-->
        <div v-if="show_type==1">
            为了更好体验，请在电脑端查看
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  components: {
    swiper,
    swiperSlide
  },
  props:{
    swiper_list:[],
    show_type:0,//展示方式 0电脑 1手机
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 100000,//切换时间
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted() {
    // current swiper instance
    // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
    // console.log("this is current swiper instance object", this.swiper);
    // this.swiper.slideTo(3, 1000, false);
  },
  methods:{
    goArticle(id){
      this.$router.push({
        path: './article',
        query: {id: id}
      })
    },
  }
};
</script>
<style scoped >

/* 媒体查询电脑 */
@media screen and (min-width: 768px) {
    .recommendPage .swiper-container{
      /* position: relative; */
      width: 40vw;
      height: 30vh;
    }  
    .recommendPage .swiper-container .swiper-slide{
      width:  40vw;
      line-height: 300px;
      color: #000;
      font-size: 16px;
      text-align: center;
    }

    
}
/* 媒体查询手机  在480到760之间*/
@media screen and (max-width: 768px) {
    .recommendPage .swiper-container{
      /* position: relative; */
      width: 100%;
      height: 24vh;
    }  
    .recommendPage .swiper-container .swiper-slide{
      width: 100%;
      line-height: 200px;
      background: #FFFFFF;
      color: #000;
      font-size: 16px;
      text-align: center;
    }
    .swiper-button-prev{
        display: none;
    }
    .swiper-button-next{
        display: none;
    }

}
</style>